import React, { Component } from 'react';
import GeneralHelper from "helper/GeneralHelper";
import sha256 from "sha256";
import GraphImage from "./GraphImage";
import NFTPreview from "./NFTPreview";

class WhyBlockchain extends Component {
    render() {
        if (!global.config || !global.templates) return;

        let config = global.config;

        let {
          styling: {
            landing: {
              highlight: {
                detailedProductSpecsItem
              },
              whyBlockchainTitle,
              whyBlockchainText
            },
          }
        } = config;

        let item = global.templates[detailedProductSpecsItem] || {};
        if (!item) return;

        return (
            <section className="why-blockchain">
                <h1 style={{textTransform: config.styling.landing.headingsCasing || "none"}}>
                    {whyBlockchainTitle ? whyBlockchainTitle : 'Unlocking the Potential of NFTs'}
                </h1>
                {whyBlockchainText ? (
                  <p>{whyBlockchainText}</p>
                ) : (
                  <p>
                    WAX Blockchain technology empowers collectors to buy {config.collectionName !== "tmnt.funko" && <>and trade</>} {config.name} on WAX marketplaces, trade them with others, and showcase their inventory on social media. The WAX Blockchain ensures your {config.name} are 100% verifiable as authentic.
                  </p>
                )}
                <div className="boxes">
                    <div className="box-container">
                        <div className="box">
                            <div className="content" id="keyval">
                                <div className="obj">Manufacturer: <b>{item?.collection?.name}</b></div>
                                <div className="obj">Card Name: <b>{item.name}</b></div>
                                <div className="obj">Date Minted: <b>{GeneralHelper.dateToDay(new Date())}</b></div>
                                <div className="obj">
                                    {(config.styling?.landing?.rarityAttribute || 'rarity')}: <b>{GeneralHelper.formatLowerCaseText(item?.data?.[(config.styling?.landing?.rarityAttribute || 'rarity')])}</b>
                                </div>
                            </div>
                            <div className="title">Detailed Product Specs</div>
                        </div>
                        <div className="box">
                            <div className="content" id="table">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Owner</th>
                                        <th>Date</th>
                                        <th>Txs</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        ["John", "Lisa", "Alex"].sort(() => Math.random() - Math.random()).map((name, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{name}</td>
                                                    <td>{GeneralHelper.dateToDay(new Date(new Date().getTime() - (i * 1000 * 60 * 60 * 24)))}</td>
                                                    <td>{sha256(name + i).substr(0, 6)}</td>
                                                </tr>
                                            )
                                        }).reverse()
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="title">Comprehensive Ownership Record</div>
                        </div>
                    </div>
                    <div className="box-container">
                        <NFTPreview className="box-img" nft={item} size={"350x600"} />
                    </div>
                    <div className="box-container">
                        <div className="box">
                            <div className="content" id="svg">
                                {GraphImage()}
                            </div>
                            <div className="title">Complete Trading History</div>
                        </div>
                        <div className="box">
                            <div className="content" id="text">
                                <span>
                                    Instantly <b>trade</b> your collectibles to <b>anyone in the world.</b>
                                </span>
                            </div>
                            <div className="title">Instant Global Marketplaces</div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default WhyBlockchain;