import React, { Component } from 'react';

export default class CopyRight extends Component {
    render() {
        let copyright = global.config.copyright;

        if(!copyright) {
            return <div className="copyright">
                <div className="item">
                    &copy; WAX.io 2020. All rights reserved.
                </div>
            </div>
        }
        
        const link = copyright.link;

        return <div className="copyright">
            {Object.values(copyright).map((row, i) => {
                if (typeof row === "object") return <></>;

                return <div className="item" key={i}>
                    {row}
                </div>
            })}
            {(link && link.text && link.url) && 
                <a href={link.url} target="_blank" rel="noopener noreferrer">{link.text}</a>
            }
        </div>
    }
}
