import React, { Component } from 'react';
import { withUAL } from '@cmichel/ual-reactjs-renderer/dist/components/provider/withUAL';
import GeneralHelper from "helper/GeneralHelper";
import NFTPreview from "./NFTPreview";

export const RARITIES = ["Common", "Uncommon", "Rare", "Epic", "Legendary", "Grail", "1 of 1", "Series"];
const RARITIES_PERCENTAGES = {
    "funko": {
        "common": 66.66,
        "rare": 33.34
    },
    "tmnt.funko": {
        "common": 49,
        "uncommon": 27,
        "rare": 14,
        "epic": 8,
        "legendary": 1.63,
        "grail": 0.37
    },
    "bigboy.funko": {
        "common": 46.33,
        "uncommon": 30,
        "rare": 16,
        "epic": 6,
        "legendary": 1,
        "grail": 0.67
    },
    "maiden.funko": {
        "common": 51.33,
        "uncommon": 28,
        "rare": 14,
        "epic": 5.40,
        "legendary": 0.90,
        "grail": 0.37
    },
    "spongebob": {
        "common": 42.6,
        "uncommon": 35.04,
        "rare": 16.82,
        "epic": 5.04,
        "legendary": 0.50
    }
};

class Rarities extends Component {
    state = {
        rarityCards: []
    }
    _mounted = false;

    componentDidMount() {
        this._mounted = true;
        let config = global.config;
        let { styling: { landing: { rarityCards } } } = config;
        this.setRarityCards(rarityCards).then();
    }

    setRarityCards = async cards => {
        if (!cards) cards = [];
        try {
            cards = (cards).split(",");
        } catch (err) {
            cards = [...cards];
        }
        let processedCards = [];
        for (let cardid of cards) {
            let template = global.templates[cardid];
            if (!template.data) {
                template.data = template.immutable_data;
            }
            processedCards.push(template);
        }
        if (!this._mounted) return;
        this.setState({
            rarityCards: processedCards,
        });
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    render() {
        let config = global.config;
        return (
            <section className="rarities">
                <h1 style={{textTransform: config.styling.landing.headingsCasing || "none"}}>
                    {config.styling?.landing?.rarityName || 'Card Rarities'}
                </h1>
                <div className="inner">
                    <div className="rarities-list">
                        {
                            this.state.rarityCards.map((card, i) => {
                                let rarityAttribute = config.styling?.landing?.rarityAttribute || 'rarity';
                                let softener = config.raritySoftener?.[card?.data?.[rarityAttribute]];
                                return (
                                    <div key={i} className="rarity-box">
                                        <NFTPreview nft={card} size={"260x260"} />
                                        <div className="name">
                                            {GeneralHelper.formatLowerCaseText(card?.data?.[rarityAttribute])}
                                        </div>
                                        { RARITIES_PERCENTAGES[config.collectionName] !== undefined &&
                                            <div>
                                                {RARITIES_PERCENTAGES[config.collectionName][card.data.rarity.toLowerCase()]} %
                                            </div>
                                        }
                                        { softener && (
                                            <div className="rarity-soft">
                                                ({ config.raritySoftener?.[card?.data?.[rarityAttribute]]})
                                            </div>
                                        )}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default withUAL(Rarities);
