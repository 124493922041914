import React, { Component } from 'react'
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

class Question extends Component {

    state = { toggled: false }

    render() {
        let { toggled } = this.state;
        let { question, answer } = this.props;
        return (
            <div className={`question ${toggled ? "toggled" : ""}`}>
                <div className="phrase" onClick={() => this.setState({toggled: !toggled})}>
                    {question}
                </div>
                <div className="answer">
                    {
                        typeof answer == "string" ?
                            <ReactMarkdown linkTarget={"_blank"} plugins={[gfm]} children={answer.split("\\n").join("\n")} />
                            :
                            answer
                    }
                </div>
            </div>
        )
    }
}

export default Question;