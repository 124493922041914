import React from 'react';
import ReactDOM from 'react-dom';
import App from './container/App';
import SiteRouter from './container/SiteRouter';
import ConfigLoader from './helper/ConfigLoader';
import ChainHelper from './helper/ChainHelper';
import { UALProvider } from "@cmichel/ual-reactjs-renderer";
import { Wax } from "@eosdacio/ual-wax";
import { ToastContainer } from "react-toastify";
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

const chainNet = {
  chainId: process.env.REACT_APP_WAX_CHAIN_ID || "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  rpcEndpoints: ChainHelper.rpcEndpoints,
};

(async() => {
  global.data_url = process.env.REACT_APP_DATA_URL || "https://sale-data.wdny.io";
  global.stats_url = process.env.REACT_APP_STATS_URL ||"https://payments.wdny.io";
  global.sitetime = Math.floor(Date.now() / 1000);

  let config = await ConfigLoader.loadConfig();
  if (!config) return alert("Nothing configured for " + window.location.hostname);
  let styling = await ConfigLoader.getStyling(config.collectionName);
  updateStyling(styling);

  const WCWAuth = new Wax([chainNet], {
    waxSigningURL: process.env.REACT_APP_WAX_SIGNING_URL || undefined,
    waxAutoSigningURL: process.env.REACT_APP_WAX_AUTOSIGNING_URL || undefined,
    appName: config.name
  });

  ReactDOM.render(
    <React.StrictMode>
      <UALProvider
        chains={[chainNet]}
        authenticators={[WCWAuth]}
        appName={config.name}
      >
        <App>
          <SiteRouter />
          <ToastContainer />
        </App>
      </UALProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

const updateStyling = styling => {
  for (let obj of Object.values(styling)) {
    document.documentElement.style.setProperty(obj.key, obj.value);
  }
}
