import React, { Component } from 'react';
import { withUAL } from '@cmichel/ual-reactjs-renderer/dist/components/provider/withUAL';
import {Link} from "react-router-dom";
import IPFSHelper from "helper/IPFSHelper";
class CompleteSet extends Component {
    render() {
        let config = global.config;

        let {
          styling: {
            landing: {
              catalogImage,
              completeSetTitle,
              completeSetText
            }
          }
        } = config;

        return (
            <section className="complete-set">
                <div className="inner">
                    <div className="preview">
                        <img src={IPFSHelper.formatToUrl(catalogImage, "533x300")} alt="" />
                    </div>
                    <div className="content">
                        <h2 style={{textTransform: config.styling.landing.headingsCasing || "none"}}>
                            {completeSetTitle ? completeSetTitle : 'Complete Your Set'}
                        </h2>
                        {completeSetText ? (
                          <p>{completeSetText}</p>
                        ) : (
                          <p>
                            Blockchain technology allows collectors to enter a marketplace where they can purchase digital {config.name}, propose and execute trades, showcase their inventory on social media and search wishlists of other traders.
                          </p>
                        )}
                        <Link to={"/catalog"}><button>View Catalog</button></Link>
                    </div>
                </div>
            </section>
        )
    }
}

export default withUAL(CompleteSet);
