import {ExplorerApi} from "atomicassets";
import {AtomicMarketApi} from "atomicmarket";
import ChainHelper from "./ChainHelper";
import CacheHelper from "./CacheHelper";

global.config = {};
global.templates = {};
global.virls = {};
global.sales = {};
global.boxes = {};
global.recipes = {};
global.faqs = {};
global.atomic = null;
global.atomicmarket = null;

let configContract = "config.nft";

const getSettings = async (
    collection_name
) => {
    let settings = {};
    try {
        settings = await fetch(global.data_url + "/" + collection_name + "/settings.json?version=" + global.sitetime).then(res => res.json());
        if (!settings || !settings?.name) {
            throw new Error("Error loading settings");
        }
    } catch (err) {
        console.error(err.toString());
    }

    return settings;
}

const loadConfig = async () => {
    let config = null;
    let lower_bound = ChainHelper.stringToNumber(window.location.hostname);
    console.log("Domain Index:", lower_bound, window.location.hostname);

    let collection = null;
    if (process.env.REACT_APP_COLLECTION_NAME) {
        collection = process.env.REACT_APP_COLLECTION_NAME;
    } else {
        // fetch collection from domain
        let {rows} = await ChainHelper.get_table_rows(configContract, "domain", configContract, 1, lower_bound, lower_bound);
        if (rows && rows.length > 0) {
            let {collection_name} = rows[0];
            collection = collection_name;
        } else {
            alert(window.location.hostname + " is not setup for this dapp.");
        }
    }
    console.log("Collection Name:", collection);
    if (!collection) {
        let error = "No collection configured.";
        alert(error);
        throw (error);
    }

    config = await getSettings(collection);
    config.walletUrl = process.env.REACT_APP_WALLET_URL || "https://www.mycloudwallet.com";
    config.marketUrl = process.env.REACT_APP_MARKET_URL || "https://wax.atomichub.io";
    config.collection_name = collection;
    config.collectionName = collection;

    console.log("Config Loaded", config);

    global.config = config;

    let modules = [];
    if (config.modules) {
        modules = config.modules.split(",");
    } else {
        console.warn("Warning: No Modules defined.");
    }

    await loadAtomic();
    if (modules.includes("auction")) await loadAtomicMarket();
    await CacheHelper.loadTemplates();
    if (modules.includes("virl")) await CacheHelper.loadVirls();
    await CacheHelper.loadSales();
    await CacheHelper.loadBoxes();
    if (modules.includes("craft")) await CacheHelper.loadRecipes();
    await CacheHelper.loadFAQs();

    // Debugging
    console.log("Config Array:", ChainHelper.destructureJSON(config));
    return config;
}

const loadAtomic = async () => {
    let api_url = process.env.REACT_APP_ATOMIC_API_URL || "https://atomic.wax.io";

    global.atomic = new ExplorerApi(api_url, "atomicassets", {fetch});
}

const loadAtomicMarket = async () => {
    let api_url = process.env.REACT_APP_ATOMIC_API_URL || "https://wax.api.atomicassets.io";

    global.atomicmarket = new AtomicMarketApi(api_url, "atomicmarket", {fetch});
}

const getStyling = async (
    collection_name
) => {
    let styling = {};
    try {
        styling = await fetch(global.data_url + "/" + collection_name + "/styling.json?version=" + global.sitetime).then(res => res.json());
        if (!styling) {
            throw new Error("Error loading styles");
        }
    } catch (err) {
        console.error(err.toString());
    }

    global.styling = styling;
    return styling;
}

export default {
    configContract,
    loadConfig,
    getStyling,
}
