import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IPFSHelper from 'helper/IPFSHelper';
import TopNav from 'components/TopNav';
import CopyRight from 'components/CopyRight';
import HowItWorks from "components/HowItWorks";
import WhyBlockchain from "components/WhyBlockchain";
import CompleteSet from "components/CompleteSet";
import Rarities from "components/Rarities";
import CardSelection from "components/CardSelection";
import BlockchainFAQ from "components/BlockchainFAQ";
import NFTPreview from "components/NFTPreview";
import BurnToEarn from "components/BurnToEarn";

export default class LearnMore extends Component {

    state = {
        top: {
            cards: []
        }
    }
    _mounted = false;

    componentDidMount() {
        this._mounted = true;
        let config = global.config;
        let { styling: { landing: { top: { cards } } } } = config;
        this.setTopCards(cards);
    }

    setTopCards = async cards => {
        if (!cards) cards = [];
        try {
            cards = (cards).split(",")
        } catch (err) {
            cards = [...cards];
        }
        let processedCards = [];
        for (let cardid of cards) {
            let template = global.templates[cardid];
            if(template) {
              if (!template.data) {
                template.data = template.immutable_data;
            }
            processedCards.push(template);
            }
        }

        if (!this._mounted) return;
        this.setState({
            top: {
                ...this.state.top,
                cards: processedCards,
            }
        })
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    render() {
        let config = global.config;

        let { top: { cards } } = this.state;
        let { styling: { landing: { top: { background, brand } } } } = config;

        let topStyle = {
            backgroundImage: "url(" + IPFSHelper.formatToUrl(background) + ")"
        };
        if (["mlb.topps", "osmfigures", "postfuture", "spongebob"].includes(config?.collection_name)) {
            topStyle.backgroundSize = "cover";
        }

        let cardBorder = document.documentElement.style.getPropertyValue("--topcards-border-color") || null;
        let cardStyle = null;
        if (cardBorder && cardBorder !== "") {
            cardStyle = {border: "1px solid " + cardBorder};
        }

        let brandCss = "brand";
        let brandImg = <img src={IPFSHelper.formatToUrl(brand, "375x100")} alt="" />;

        if (["tmnt.funko", "princessb", "maiden.funko", "msuniverse", "hotwheels", "leifer"].includes(config?.collection_name)) {
            brandCss = "brand-2x";
            brandImg = <img src={IPFSHelper.formatToUrl(brand, "600x160")} alt="" />;
        } else if (config?.collection_name === "carbonoffset") {
            brandCss = "brand-3x";
            brandImg = <img src={IPFSHelper.formatToUrl(brand, "900x240")} alt="" />;
        }

        return (
            <main id="info">
                <div className="top LearnMore" style={topStyle}>
                    <TopNav />
                    <header
                        style={
                            config.collection_name === 'bcbrawlers' ? {
                                paddingBottom: '180px'
                            } : null
                        }
                    >
                        <div className={brandCss}>
                            {brandImg}
                        </div>
                        <div className={brandCss === "brand-3x" ? "cards cards-small" : "cards"}>
                            {
                                cards.map((card, i) => {
                                    return <NFTPreview key={i} nft={card} size={"350x600"} style={cardStyle} />;
                                })
                            }
                        </div>
                        {config?.collection_name === "mlb.topps" &&
                            <div className="title-text">
                                Topps Series 1<br/>
                                NFT Collection
                            </div>
                        }
                        {config?.collection_name === "mlb.topps" &&
                            <p className="blurb">
                                The Topps® MLB NFT Collection celebrates 70 years of Topps Baseball, showcasing modern-day stars in new and classic Topps® card designs.<br/>
                                Collectors can find their favorite players reimagined as digitally enhanced, officially licensed Topps MLB NFT collectibles – ushering in a new age of baseball card collecting.
                            </p>
                        }
                        <Link to={config.styling?.landing?.top?.ctaRedirect || "shop"}>
                            <button>{config.styling?.landing?.top?.cta || 'BUY NOW'}</button>
                        </Link>
                    </header>
                </div>

                {!config?.layout?.version && // Default version - backwards compatibility
                    <>
                        <WhyBlockchain />
                        <CompleteSet />
                        <Rarities />
                    </>
                }

                {config?.layout?.version === "topps" &&
                    <>
                        <HowItWorks />
                        <CardSelection />
                        {config?.collection_name === "mlb.topps" &&
                            <BurnToEarn />
                        }
                        <BlockchainFAQ />
                    </>
                }

                <CopyRight />
            </main>
        )
    }
}
