import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ShareIcon from 'assets/icons/share.svg';
import IPFSHelper from "helper/IPFSHelper";

const DEFAULT_SORTED_LINKS = [
    "unpack",
    "craft",
    "leaderboard",
    "inventory",
    "shop",
    "auction",
    "virl",
    "catalog",
    "faq",
    "tos",
    "eula"
];

const legacyMediumLogoSizes = ["tmnt.funko", "ali", "osmfigures", "princessb", "maiden.funko", "hasbro", "msuniverse"];

export default class Navigation extends Component {

    share = () => {
        let config = global.config;
        navigator.share({title: config.share?.title,  text: config.share?.text, url: window.location.origin})
    }

    render() {
        let config = global.config;
        let modules = [];
        if (config.modules) {
            modules = config.modules.split(",");
        } else {
            console.warn("Warning: No Modules defined.");
        }
        let { styling: { app: { nav: { logo, secondaryLogo, logoSize } } } } = config;
        let shareAvailable = navigator.share && (config.share?.title || config.share?.text);

        if(!logoSize) {
            if(legacyMediumLogoSizes.includes(config?.collection_name)) {
                logoSize = "medium";
            } else {
                logoSize = "small";
            }
        }

        const links = [
            {
                key: "unpack",
                show: modules.includes("unbox"),
                element: <NavLink key="unpack" to="/unpack" className="link">OPEN PACKS</NavLink>
            },
            {
                key: "craft",
                show: modules.includes("craft"),
                element: <NavLink key="craft" to="/craft" className="link">
                    {config.navigation?.craftText ? config.navigation.craftText.toUpperCase() : "CRAFT"}
                </NavLink>
            },
            {
                key: "leaderboard",
                show: modules.includes("leaderboard"),
                element: <NavLink key="leaderboard" to="/leaderboard" className="link">
                    {config.navigation?.leaderboardText ? config.navigation.leaderboardText.toUpperCase() : "LEADERBOARD"}
                </NavLink>
            },
            {
                key: "inventory",
                show: true,
                element: <a key="inventory" href={`${config.walletUrl}/nfts?collection_name=${config.collectionName}`} className="link" target="_blank" rel="noopener noreferrer">INVENTORY</a>
            },
            {
                key: "shop",
                show: modules.includes("shop"),
                element: <NavLink key="shop" to="/shop" className="link">SHOP</NavLink>
            },
            {
                key: "auction",
                show: modules.includes("auction"),
                element: <NavLink key="auction" to="/auction" className="link">AUCTION</NavLink>
            },
            {
                key: "virl",
                show: modules.includes("virl"),
                element: <NavLink key="virl" to="/virl" className="link">
                    {config.navigation?.virlText ? config.navigation.virlText.toUpperCase() : "vIRL"}
                </NavLink>
            },
            {
                key: "catalog",
                show: true,
                element: <NavLink key="catalog" to="/catalog" className="link">CATALOG</NavLink>
            },
            {
                key: "redeem",
                show: modules.includes("redeem"),
                element: <NavLink key="redeem" to="/redeem" className="link">CLAIM NFT</NavLink>
            },
            {
                key: "faq",
                show: true,
                element: <NavLink key="faq" to="/faq" className="link">FAQ</NavLink>
            },
            {
                key: "tos",
                show: true,
                element: <a key="tos" href="https://wdny.io/terms-of-service/" target="_blank" className="link" rel="noopener noreferrer">TOS</a>
            },
            {
                key: "eula",
                show: config.eula?.text !== undefined,
                element: <a key="eula" href={config.eula?.link} target="_blank" className="link" rel="noopener noreferrer">
                    {config.eula?.text.toUpperCase()}
                </a>
            },
            {
                key: "clicktoclaim",
                show: modules.includes("clicktoclaim"),
                element: <NavLink key="clicktoclaim" to="/drops" className="link">CLICK TO CLAIM</NavLink>
            }
        ];

        const sortedLinks = config.navigation?.sortedTabs !== undefined ?
            config.navigation.sortedTabs.split(",") : DEFAULT_SORTED_LINKS;

        return (
            <nav className="nav-original">
                <div className="color-bar"></div>
                <div className="navigation">
                    <NavLink to="/">
                        <div className={`logo ${logoSize}`}>
                            <img src={IPFSHelper.formatToUrl(logo, "200x80")} alt="" />
                            {secondaryLogo && <img src={IPFSHelper.formatToUrl(secondaryLogo, "200x80")} alt="" />}
                        </div>
                    </NavLink>
                    <div className="links">
                        {
                            shareAvailable && (
                                <div className="link share" onClick={this.share}>
                                    <img src={ShareIcon} alt="" />
                                </div >
                            )
                        }
                        {sortedLinks.map(linkKey => {
                            const link = links.find(l => l.key === linkKey);

                            if(link === undefined) return;
                            if(!link.show) return;

                            return link.element;
                        })}
                    </div>
                </div>
            </nav>
        )
    }
}
