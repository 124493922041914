const loadTemplates = async () => {
    let templates = {};
    try {
        templates = await fetch(global.data_url + "/" + global.config.collection_name + "/templates.json?version=" + global.sitetime).then(res => res.json());
        if (!templates) {
            throw new Error("Error loading templates");
        }
    } catch (err) {
        console.error(err.toString());
    }

    global.templates = templates;
    return templates;
}

const loadVirls = async () => {
    let res = {};
    try {
        res = await fetch(global.data_url + "/" + global.config.collection_name + "/virls.json?version=" + global.sitetime).then(res => res.json());
        if (!res) {
            throw new Error("Error loading virls");
        }
    } catch (err) {
        console.error(err.toString());
    }
    let {virls, rules} = res;
    global.virls = virls;
    global.virlrules = rules;
    return res;
}

const loadSales = async () => {
    let sales = {};
    try {
        sales = await fetch(global.data_url + "/" + global.config.collection_name + "/sales.json?version=" + global.sitetime).then(res => res.json());
        if (!sales) {
            throw new Error("Error loading sales");
        }
    } catch (err) {
        console.error(err.toString());
    }

    global.sales = sales;
    return sales;
}

const loadBoxes = async () => {
    let unbox = {};
    try {
        unbox = await fetch(global.data_url + "/" + global.config.collection_name + "/unbox.json?version=" + global.sitetime).then(res => res.json());
        if (!unbox) {
            throw new Error("Error loading boxes");
        }
    } catch (err) {
        console.error(err.toString());
    }
    let {boxes, rules} = unbox;
    global.boxes = Object.values(boxes);
    global.boxrules = Object.values(rules);
    return boxes;
}

const loadRecipes = async () => {
    let craft = {};
    try {
        craft = await fetch(global.data_url + "/" + global.config.collection_name + "/craft.json?version=" + global.sitetime).then(res => res.json());
        if (!craft) {
            throw new Error("Error loading recipes");
        }
    } catch (err) {
        console.error(err.toString());
    }
    let {
       recipes,
       rules,
       categories
    } = craft;

    global.recipes = recipes;
    global.reciperules = rules;
    global.categories = categories;
    return recipes;
}

const loadFAQs = async () => {
    let faqs = {};
    try {
        faqs = await fetch(global.data_url + "/" + global.config.collection_name + "/faq.json?version=" + global.sitetime).then(res => res.json());
        if (!faqs) {
            throw new Error("Error loading faq");
        }
    } catch (err) {
        console.error(err.toString());
    }

    global.faqs = faqs;
    return faqs;
}

export default {
    loadTemplates,
    loadVirls,
    loadSales,
    loadBoxes,
    loadRecipes,
    loadFAQs,
}

    // getTemplate,
    // getAllTemplates,
    // getCachedTemplate,
    // setCacheData,
    // getCacheData
