/* global BigInt */
const formatLowerCaseText = text => {
    // custom names
    if (text === "cardid") return "Card ID";
    if (!text) return text;
    if (typeof text != "string") return text;
    text = text.split("_").map(word => {
        let [firstLetter] = word.split("");
        word = firstLetter.toUpperCase() + word.substr(1);
        return word;
    }).join(" ");
    if (text === "Collectors Edition") text = "Collector's Edition";
    if (text === "Series1") text = "Series 1";
    return text;
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]

const dateToDay = (date) => {
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

function msToTime(duration, { 
    useAbbreviations = false,
    prependZeroForDay = true
} = {}) {
    let milliseconds = parseInt((duration % 1000) / 10),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
        days = Math.floor((duration / (1000 * 60 * 60 * 24)) % 365);

    days = days < 10 && prependZeroForDay ? "0" + days : days;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    milliseconds = milliseconds < 10 ? "0" + milliseconds : milliseconds;
    
    if(useAbbreviations) {
        return (
            ((parseFloat(days) > 0) ? days + "d " : "") +
            hours +
            "h " +
            minutes +
            "m " +
            seconds +
            "s"
        );    
    }
    
    return (
        ((parseFloat(days) > 0) ? days + ":" : "") +
        hours +
        ":" +
        minutes +
        ":" +
        seconds +
        (
            (parseFloat(days) < 1 && parseFloat(hours) < 1) ? "." + milliseconds : ""
        )
    );
}


const MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];


function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 10) {
        // Adding leading zero to minutes
        minutes = `0${minutes}`;
    }

    if (prefomattedDate) {
        // Today at 10:20
        // Yesterday at 10:20
        return `${prefomattedDate} at ${hours}:${minutes}`;
    }

    if (hideYear) {
        // 10. January at 10:20
        return `${day}. ${month} at ${hours}:${minutes}`;
    }

    // 10. January 2017. at 10:20
    return `${day}. ${month} ${year}. at ${hours}:${minutes}`;
}

// --- Main function
function timeAgo(dateParam) {
    if (!dateParam) {
        return null;
    }

    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();

    if (seconds < 5) {
        return 'now';
    } else if (seconds < 60) {
        return `${seconds} seconds ago`;
    } else if (seconds < 90) {
        return 'about a minute ago';
    } else if (minutes < 60) {
        return `${minutes} minutes ago`;
    } else if (isToday) {
        return getFormattedDate(date, 'Today');
    } else if (isYesterday) {
        return getFormattedDate(date, 'Yesterday');
    } else if (isThisYear) {
        return getFormattedDate(date, false, true);
    }

    return getFormattedDate(date);
}

function charToSymbol(c){
    if (typeof c == 'string') c = c.charCodeAt(0);
    if (c >= 'a'.charCodeAt(0) && c <= 'z'.charCodeAt(0)) {
        return c - 'a'.charCodeAt(0) + 6;
    }
    if (c >= '1'.charCodeAt(0) && c <= '5'.charCodeAt(0)) {
        return c - '1'.charCodeAt(0) + 1;
    }
    return 0;
}

function nameToUint64(s){
    let n = 0n;
    let i = 0;
    for (; i < 12 && s[i]; i++) {
        n |= BigInt(charToSymbol(s.charCodeAt(i)) & 0x1f) << BigInt(64 - 5 * (i + 1));
    }
    if (i === 12) {
        n |= BigInt(charToSymbol(s.charCodeAt(i)) & 0x0f);
    }
    return n.toString();
}

/* taken from https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4#file-shuffle-js-L5 */
const shuffleArray = arr => arr
  .map(a => [Math.random(), a])
  .sort((a, b) => a[0] - b[0])
  .map(a => a[1]);

export default {
    formatLowerCaseText,
    dateToDay,
    msToTime,
    timeAgo,
    nameToUint64,
    charToSymbol,
    shuffleArray
}
