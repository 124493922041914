import React from 'react'

function GraphImage () {
    return (
        <svg className={"graphImage"} width="366" height="120" viewBox="12 -13 366 174" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M366 150H24V0" stroke="#AFAFAF" strokeWidth="3" className={"axis-line"}/>
            <path d="M27 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M91 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M155 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M219 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M283 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M59 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M123 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M187 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M251 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M315 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M35 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M99 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M163 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M227 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M291 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M67 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M131 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M195 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M259 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M323 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M347 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M51 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M115 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M179 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M243 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M307 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M83 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M147 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M211 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M275 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M339 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M43 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M107 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M171 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M235 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M299 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M75 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M139 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M203 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M267 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M331 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M355 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M363 150V0" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 3H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 27H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 51H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 75H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 107H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 139H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 19H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 43H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 67H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 99H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 131H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 91H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 123H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 11H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 35H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 59H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 83H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 115H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 147H366" stroke="black" strokeOpacity="0.05" className="grid-lines"/>
            <path d="M24 144.5L85.5 86.5L135.5 108.5L188.5 91.5L240.5 61.5L259.5 33.5L310.5 49.5L362.5 20.5" stroke="#AFAFAF" strokeWidth="3" className={"line-color"} />
            {/* Dots */}
            <rect x="83" y="84" width="5" height="5" rx="2.5" fill="black" className={"dot"} />
            <rect x="133" y="106" width="5" height="5" rx="2.5" fill="black" className={"dot"} />
            <rect x="186" y="89" width="5" height="5" rx="2.5" fill="black" className={"dot"} />
            <rect x="238" y="59" width="5" height="5" rx="2.5" fill="black" className={"dot"} />
            <rect x="257" y="31" width="5" height="5" rx="2.5" fill="black" className={"dot"} />
            <rect x="308" y="47" width="5" height="5" rx="2.5" fill="black" className={"dot"} />
            <rect x="360" y="18" width="5" height="5" rx="2.5" fill="black" className={"dot"} />
        </svg>
    )
}

export default GraphImage;