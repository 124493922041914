import React, { Component } from 'react';
import { withUAL } from '@cmichel/ual-reactjs-renderer/dist/components/provider/withUAL';
import {Link} from "react-router-dom";
import IPFSHelper from "helper/IPFSHelper";

class BurnToEarn extends Component {
    render() {
        return (
            <section className="burn-to-earn">
                <div className="heading">
                    <h2>“Burn to Earn”</h2>
                    <h2>Topps® 1952 Redux NFTs!</h2>
                </div>
                <div className="overview">
                    <video
                        src={IPFSHelper.formatToUrl("QmbFoPdDQqKHoQyFwFQcddrU1qxgAmPHqxxcCvhna5wkN5/MLB%20STANDARD%20BURN%20W%204.mp4", "600x338")}
                        autoPlay={true}
                        playsInline={true}
                        muted={true}
                        loop={true}
                        controls={false}
                    />
                    <p>
                        Two new “Burn to Earn“ Events are available each week starting every Tuesday at 5pm EST to receive event-exclusive packs containing <strong>Topps&reg; 1952 Redux NFTs</strong>. Click the button below to learn more about which Series 1 NFTs are eligible to CRAFT for these rarer throwback cards. Final “Burn to Earn“ event begins on 5/18 and ends 5/25 at 12pm EST.
                    </p>
                </div>
                <Link to={"/craft"}><button>CRAFT</button></Link>
            </section>
        )
    }
}

export default withUAL(BurnToEarn);
