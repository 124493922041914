import React,{ useRef, useEffect } from 'react';

const VideoAsset = (props) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const { current: videoElement } = videoRef;
        videoElement.setAttribute('muted', '');
    }, []);

    return (
        <video
            className={props.className}
            onClick={props.onClick}
            src={props.src}
            ref={videoRef}
            autoPlay={true}
            playsInline={true}
            muted={props.muted ?? true}
            loop={true}
            style={props.style}
        />
    );
}

export default VideoAsset;
