import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { withUAL } from '@cmichel/ual-reactjs-renderer/dist/components/provider/withUAL';
import WAXLogoDark from 'assets/images/wax-logo-dark.png';
import IPFSHelper from "helper/IPFSHelper";

const legacyMediumLogoSizes = ["tmnt.funko", "ali", "osmfigures", "bigboy.funko", "princessb", "maiden.funko"];

class TopNav extends Component {

    login = () => {
        let { props } = this;
        props.ual.logout();
        props.ual.showModal();
    };

    render() {
        let activeUser = this.props.ual.activeUser;
        let config = global.config;
        let modules = [];
        if (config.modules) {
            modules = config.modules.split(",");
        } else {
            console.warn("Warning: No Modules defined.");
        }
        let { styling: { app: { top: { logo, logoSize } } } } = config;

        if(!logoSize) {
            if(legacyMediumLogoSizes.includes(config?.collection_name)) {
                logoSize = "medium";
            } else {
                logoSize = "small";
            }
        }

        const topnavClassName = config?.collection_name === "hasbro" ? "topnav hasbro" : "topnav";

        return (
            <section className={topnavClassName}>
                <div className="signin">
                    {
                        !this.props.hideLogo && (
                            <Link className="logo-link" to={"/"}>
                                <img className={`logo ${logoSize}`}
                                    src={IPFSHelper.formatToUrl(logo, "280x100")} alt="" />
                            </Link>
                        )
                    }
                    {config?.layout?.version === "topps" &&
                        <div className="links">
                            {modules.includes("unbox") && <NavLink to="/unpack" className="link">OPEN PACKS</NavLink>}
                            {modules.includes("shop") && <NavLink to="/shop" className="link">SHOP</NavLink>}
                            <a href={`${config.marketUrl}/market?collection_name=${config.collectionName}`} className="link" target="_blank" rel="noopener noreferrer"><img src={IPFSHelper.formatToUrl("QmcTYrW37o7nQ2rJ3md4DboHhuyt6UTFHvumjuVhxe5yQy", "16x16")} alt="" style={{ height: "16px", verticalAlign: "bottom" }} /> MARKETPLACE</a>
                            {modules.includes("craft") && <NavLink to="/craft" className="link">CRAFT</NavLink>}
                            {modules.includes("leaderboard") && <NavLink to="/leaderboard" className="link">LEADERBOARD</NavLink>}
                            <NavLink to="/catalog" className="link">ALL COLLECTIBLES</NavLink>
                            <a href={`${config.walletUrl}/nfts?collection_name=${config.collectionName}`} className="link" target="_blank" rel="noopener noreferrer">MY COLLECTION</a>
                            <NavLink to="/faq" className="link">FAQ</NavLink>
                            <a href="https://wdny.io/terms-of-service/" target="_blank" className="link" rel="noopener noreferrer">TOS</a>
                        </div>
                    }
                    {
                        config.legacy?.linkText && config.legacy?.linkURL && (
                            <a href={String(config.legacy?.linkURL)} className="legacylink">
                                <button>{config.legacy?.linkText}</button>
                            </a>
                        )
                    }
                    <div className="right">
                        {
                            activeUser ?
                                <>
                                    <button className={"link"} onClick={() => this.props.ual.logout()}>
                                        LOGOUT
                                    </button>
                                    <a href={config.walletUrl} target="_blank" className="profile" rel="noopener noreferrer">
                                        <img src={WAXLogoDark} alt="" />
                                        {activeUser.accountName}
                                    </a>
                                    <button className={"link hasbro-link"} onClick={() => window.open(config.walletUrl, "_blank")}>
                                        WAX.IO
                                    </button>
                                </>
                                :
                                <>
                                    <button className={"link action"} onClick={this.login}>
                                        SIGN UP / SIGN IN
                                    </button>
                                </>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default withUAL(TopNav);
