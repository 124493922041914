import React, { Component } from 'react';

export default class App extends Component {
    render() {
        let config = global.config;
        return (
            <div className={"app-container" + (config?.layout?.version === "topps" ? " layout-topps" : "")} style={{background: config.styling?.app?.background}}>
                {
                    this.props.children
                }
            </div>
        )
    }
}
