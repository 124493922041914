let cache_location = process.env.REACT_APP_CACHE_URL ?? null;
let ipfs_url = process.env.REACT_APP_IPFS_URL ?? "https://gateway.pinata.cloud/ipfs/";

const formatToUrl = (hash, size= "", format = "") => {
    if (!hash) return;
    hash = String(hash).trim();
    if (hash.startsWith("data:")) return hash;
    if (hash.startsWith("http")) return hash;
    if (cache_location) {
        return toCache(hash, size, format);
    }
    return toIPFS(hash, size, format);
}

const toCache = (hash, size= "", format = "") => {
    if (format !== "") {
        format = ".".format;
    }
    return cache_location + "/" + hash + "/" + size + format;
}

const toIPFS = (hash, size= "", format = "") => {
    if (format !== "") {
        format = ".".format;
    }
    return ipfs_url + hash;
}

export default {
    formatToUrl,
}
