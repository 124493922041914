import React, { Component } from 'react';
import { withUAL } from '@cmichel/ual-reactjs-renderer/dist/components/provider/withUAL';
import IPFSHelper from "helper/IPFSHelper";

class HowItWorks extends Component {
    render() {
        let config = global.config;

        return (
            <section className="how-it-works">
                <h2>How it works</h2>
                <div className="explain">
                    <a className="step step-1" href={`${config.marketUrl}/market?collection_name=${config.collectionName}`} target="_blank" rel="noopener noreferrer">
                        <img src={IPFSHelper.formatToUrl("QmWpiX4bNwxijXYDoap7M8dxQXQVBhAXoD72fQp5N8maWw/ATOMIC_ICON_BLACK%20%281%29.png", "200x200")} alt="" />
                        <p>
                            Enter Marketplace where you can buy, trade and sell your digital assets (NFTs)
                        </p>
                    </a>
                    <a className="step step-2" href={`${config.walletUrl}/nfts?collection_name=${config.collectionName}`} target="_blank" rel="noopener noreferrer">
                        <img src={IPFSHelper.formatToUrl("QmWpiX4bNwxijXYDoap7M8dxQXQVBhAXoD72fQp5N8maWw/WAX_ICON.png", "200x200")} alt="" />
                        <p>
                            Digital Collectibles you collect are stored<br/>and viewable in your WAX Cloud Wallet<br/>(This is your account)
                        </p>
                    </a>
                    <div className="step step-3">
                        <img src={IPFSHelper.formatToUrl("QmWpiX4bNwxijXYDoap7M8dxQXQVBhAXoD72fQp5N8maWw/ATOMIC-ICON-BLACK.png", "200x200")} alt="" />
                        <p>
                            Earn unique rewards through Burn events!
                        </p>
                    </div>
                </div>
            </section>
        )
    }
}

export default withUAL(HowItWorks);
