import React, { Component } from 'react';
import { withUAL } from '@cmichel/ual-reactjs-renderer/dist/components/provider/withUAL';
import GeneralHelper from "helper/GeneralHelper";
import {Link} from "react-router-dom";
import NFTPreview from "./NFTPreview";

class CardSelection extends Component {
    state = {
        rarityCards: []
    }
    _mounted = false;

    componentDidMount() {
        this._mounted = true;
        let config = global.config;
        let { styling: { landing: { rarityCards } } } = config;
        this.setRarityCards(rarityCards);
    }

    setRarityCards = async cards => {
        if (!cards) cards = [];
        try {
            cards = (cards).split(",")
        } catch (err) {
            cards = [...cards];
        }
        let processedCards = [];
        for (let cardid of cards) {
            let template = global.templates[cardid];
            if (!template.data) {
                template.data = template.immutable_data;
            }
            processedCards.push(template);
        }
        if (!this._mounted) return;
        this.setState({
            rarityCards: processedCards,
        })
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    render() {
        let config = global.config;

        return (
            <section className="card-selection">
                <div className="overview">
                    <h2>{config.styling?.landing?.selectionTitle || 'Choose from a unique selection of collectible designs!'}</h2>
                    {config.styling?.landing?.selectionDescription &&
                        <p>{config.styling?.landing?.selectionDescription}</p>
                    }
                </div>
                <div className="inner">
                    <div className="rarities-list">
                        {
                            this.state.rarityCards.map((card, i) => {
                                let rarityAttribute = config.styling?.landing?.rarityAttribute || 'rarity';
                                let softener = config.raritySoftener?.[card?.data?.[rarityAttribute]];
                                return (
                                    <div key={i} className="rarity-box">
                                        <NFTPreview nft={card} size={"260x260"} />
                                        <div className="name">{GeneralHelper.formatLowerCaseText(card?.data?.[rarityAttribute])}</div>
                                        {/* <div className="rarity"></div> */}
                                        { softener && (
                                            <div className="rarity-soft">
                                                ({ config.raritySoftener?.[card?.data?.[rarityAttribute]]})
                                            </div>
                                        )}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    <Link to={"/catalog"}><button>Browse All {config?.unit_label}</button></Link>
                }
            </section>
        )
    }
}

export default withUAL(CardSelection);
