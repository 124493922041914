import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import LearnMore from 'pages/LearnMore';
import Navigation from './Navigation';
import Loading from '../components/Loading';

const Welcome = Loadable({
  loader: () => import(/* webpackChunkName: "welcome" */ 'pages/welcome/Welcome'),
  loading: Loading
});

const ClickToClaim = Loadable({
    loader: () => import(/* webpackChunkName: "clickToClaim" */ 'pages/clickToClaim/ClickToClaim'),
    loading: Loading
});

const Catalog = Loadable({
    loader: () => import(/* webpackChunkName: "catalog" */ 'pages/catalog/Catalog'),
    loading: Loading
});

const CardLabsCatalog = Loadable({
    loader: () => import(/* webpackChunkName: "cardLabsCatalog" */ 'pages/craft/CardLabsCatalog'),
    loading: Loading
});

const CardLabsResult = Loadable({
    loader: () => import(/* webpackChunkName: "cardLabsResult" */ 'pages/craft/CardLabsResult'),
    loading: Loading
});

const RedeemCode = Loadable({
    loader: () => import(/* webpackChunkName: "redeemCode" */ 'pages/redeem/RedeemCode'),
    loading: Loading
});

const Shop = Loadable({
    loader: () => import(/* webpackChunkName: "shop" */ 'pages/Shop'),
    loading: Loading
});

const Unbox = Loadable({
    loader: () => import(/* webpackChunkName: "unbox" */ 'pages/Unbox'),
    loading: Loading
});

const UnboxView = Loadable({
    loader: () => import(/* webpackChunkName: "unboxView" */ 'pages/UnboxView'),
    loading: Loading
});

const Virl = Loadable({
    loader: () => import(/* webpackChunkName: "virl" */ 'pages/Virl/Virl'),
    loading: Loading
});

const Leaderboard = Loadable({
    loader: () => import(/* webpackChunkName: "leaderboard" */ 'pages/Leaderboard'),
    loading: Loading
});

const AuctionList = Loadable({
    loader: () => import(/* webpackChunkName: "auctionList" */ 'pages/AuctionList'),
    loading: Loading
});

const Eula = Loadable({
    loader: () => import(/* webpackChunkName: "eula" */ 'pages/eula/Eula'),
    loading: Loading
});

const FAQ = Loadable({
    loader: () => import(/* webpackChunkName: "faq" */ 'pages/FAQ'),
    loading: Loading
});

export const SiteRouter = () => {
    const config = global.config;
    const modules = config.modules ? config.modules.split(",") : [];

    return (
        <BrowserRouter>
            {!config.layout?.version && <Navigation />}
            <Switch>
                <Route
                    exact path="/"
                    component={
                        () => (config.homepage?.enable === 'disabled' && config.homepage?.redirect) ?
                            <Redirect to={config.homepage?.redirect} /> :
                            <LearnMore />
                    }
                />
                {modules.includes("welcome") && <Route exact path="/welcome" component={Welcome} />}
                {modules.includes("unbox") && <Route exact path="/unpack" component={Unbox} />}
                {modules.includes("unbox") && <Route exact path="/unpack/:assoc_id" component={UnboxView} />}
                {modules.includes("shop") && <Route exact path="/shop" component={Shop} />}
                {modules.includes("auction") && <Route exact path="/auction" component={AuctionList} />}
                {modules.includes("virl") && <Route exact path="/virl" component={Virl} />}
                {modules.includes("redeem") && <Route exact path="/redeem" component={RedeemCode} />}
                {modules.includes("craft") && <Route exact path="/craft" component={CardLabsCatalog} />}
                {modules.includes("craft") && <Route exact path="/craft/:assoc_id" component={CardLabsResult} />}
                <Route exact path="/catalog" component={Catalog} />
                {modules.includes("leaderboard") && <Route exact path="/leaderboard" component={Leaderboard} />}
                <Route exact path="/faq" component={FAQ} />
                <Route exact path="/eula" component={Eula} />
                {modules.includes("clicktoclaim") && <Route exact path="/drops" component={ClickToClaim} />}
                <Redirect to="/" />
            </Switch>
        </BrowserRouter>
    )
}

export default SiteRouter;
