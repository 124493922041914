import React, { Component } from 'react'
import { withUAL } from '@cmichel/ual-reactjs-renderer/dist/components/provider/withUAL';
import Question from "./Question";

class BlockchainFAQ extends Component {

    state = {
        questions: [],
        faq: [],
        loading: true
    }
    _mounted = false;

    componentDidMount () {
        this._mounted = true;
        this.setQuestions();
        this.loadFAQ();
    }

    setQuestions = async () => {
        let config = global.config;
        let questions = config?.styling?.landing?.why?.questions ?? "";
        if (!questions) questions = [];
        try {
            questions = (questions).split(",")
        } catch (err) {
            questions = [...questions];
        }
        if (!this._mounted) return;
        this.setState({
            questions: questions
        })
    }

    loadFAQ = async () => {
        if (!this._mounted) return;
        this.setState({
            faq: global.faqs,
            loading: false
        })
    }

    componentWillUnmount () {
        this._mounted = false;
    }

    render() {
        return (
            <section className="blockchain-faq">
                <h2>Why choose Blockchain?</h2>
                <div className="questions">
                    {
                        this.state.questions.map((index, i) => {
                            let question = this.state.faq[index];
                            return (
                                <Question key={i} {...question} />
                            )
                        })
                    }
                    {
                        (this.state.faq.length === 0 && !this.state.loading) ?
                            (
                                <>
                                    Some default text
                                </>
                            )
                            : null
                    }
                </div>
            </section>
        )
    }
}

export default withUAL(BlockchainFAQ);
