import { Loader } from "semantic-ui-react";

import styles from './Loading.module.css';

const Loading = () => (
    <div className={styles.loader}>
        <Loader active inline="centered" size="large" />
    </div>
)

export default Loading;